/* eslint-disable no-restricted-syntax */
import Cookies from 'js-cookie';
import axios from '@shared/config/baseAPI';

const userToken = Cookies.get('token'); // token from cookies

const deviceId = Cookies.get('deviceId'); // change this with your device id

export const userLogout = async (_token) => {
  try {
    return await axios.post('/site/login-out', null, { headers: { 'device-id': `${deviceId}`, 'x-kumu-auth': `${_token}` } });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const userOnRender = async () => {
  try {
    return await axios.post('/user/profile', null, { headers: { 'device-id': `${deviceId}`, 'x-kumu-auth': `${userToken}` } });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
