/* eslint-disable react/jsx-no-undef */
/* eslint-disable brace-style */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable prefer-const */
import React, {
  useState, useEffect, useMemo,
} from 'react';

// For Login Functionality
import Cookies from 'js-cookie';

// Coins Context

import './App.css';
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import CookieConsent from 'react-cookie-consent';
import { Typography } from '@material-ui/core';
import uniqid from 'uniqid';
import axios from '@shared/config/baseAPI';
import { StreamSegmentContext } from '@services/context/CoinsContext';
import { UserContext } from '@context/UserContext';
import { userOnRender } from '@shared/services/shareAuthenticationApi';
import { LastLocationProvider } from 'react-router-last-location';
// loadable components
import loadable from '@loadable/component';

const Navbar = loadable(() => import('@layouts/NavBar/components/NavBar'));
const Footer = loadable(() => import('@layouts/Footer/components/Footer'));
const Profile = loadable(() => import('@pages/Profile/Profile'));
const Live = loadable(() => import('@pages/Browse/Browse'));
const ProfileKlips = loadable(() => import('@pages/ShortVideo/ShortVideo'));
const HomePage = loadable(() => import('@pages/Home/Home'));
const BanUser = loadable(() => import('@features/UserAccountBan/components/UserAccountBan'));
const LockUser = loadable(() => import('@features/UserAccountLock/components/UserAccountLock'));
const AccountInformation = loadable(() => import('@pages/Account/Account'));
const Wallet = loadable(() => import('@components/pages/wallet/Wallet'));
const ErrorWallet = loadable(() => import('@components/pages/wallet/ErrorWalletPage'));
const Coins = loadable(() => import('@components/pages/coins/Coins'));
const KlipsInner = loadable(() => import('@pages/BrowseCategory/BrowseCategory'));
const MaintenancePage = loadable(() => import('@pages/Maintenance/Maintenance'));
const Payment = loadable(() => import('@components/pages/coins/Payment'));
const CoinPaymentStatus = loadable(() => import('@components/pages/coins/CoinPaymentStatus'));
const ReferralLeaderboard = loadable(() => import('@components/pages/coins/Referral-leaderboard'));
const SearchPage = loadable(() => import('@pages/Search/Search'));

function App() {
  // eslint-disable-next-line func-names
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line func-names
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1983914755180097',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v7.0',
      });
    };
  }

  // Load the SDK asynchronously
  // eslint-disable-next-line func-names
  if (typeof document !== 'undefined') {
    // eslint-disable-next-line func-names
    (function (d, s, id) {
      let js; const
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      // eslint-disable-next-line prefer-const
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v3.2&appId=1983914755180097&autoLogAppEvents=1';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  const [loggedIn, setLoggedIn] = useState([]);
  const providerValue = useMemo(() => ({ loggedIn, setLoggedIn }), [loggedIn, setLoggedIn]);
  // device id state
  const [deviceId, setDeviceId] = useState('');

  // Coins Context
  const [segmentCoinsContext, setSegmentCoinsContext] = useState({});
  const providerCoinsContext = useMemo(() => ({
    segmentCoinsContext,
    setSegmentCoinsContext,
  }), [segmentCoinsContext, setSegmentCoinsContext]);

  const [banModalStatus, setBanModalStatus] = useState(false);
  const [userBanDetails, setUserBanDetails] = useState([]);

  useEffect(() => {
    const existingDeviceId = Cookies.get('deviceId');
    if (existingDeviceId) {
      setDeviceId(existingDeviceId);
    } else {
      const generateUniqId = uniqid();
      setDeviceId(generateUniqId);
      Cookies.set('deviceId', generateUniqId, { expires: 365, secure: true });
    }
  }, []);

  useEffect(() => {
    if (Cookies.get('token')) {
      userOnRender()
        .then(((response) => {
          if (response.status === 200) {
            if (response.data.code === '200') {
              setLoggedIn(response.data.data);
              if (response.data.data.user_status === 'ban') {
                setUserBanDetails(response.data.data.banDetails);
              }
              setBanModalStatus(true);
            } else if (response.data.code === '401') {
              Cookies.remove('token');
              Cookies.remove('uid');
            }
          }
        }))
        .catch((error) => {
          // eslint-disable-next-line no-restricted-syntax
          console.error(error);
        });
    }
  }, []);

  const [onMaintenance, setMaintenance] = useState(false);
  useEffect(() => {
    setMaintenance(process.env.REACT_APP_ON_MAINTENANCE);
  }, [onMaintenance]);

  // eslint-disable-next-line consistent-return
  const renderBanOrLockModal = () => {
    if (loggedIn.user_status === 'ban') {
      return (<BanUser banModalStatus={banModalStatus} userBanDetails={userBanDetails} />);
    // eslint-disable-next-line no-else-return
    } else if (loggedIn.user_status === 'lock') {
      return (<LockUser banModalStatus={banModalStatus} />);
    }
  };

  const settingsRoute = () => {
    if (loggedIn) {
      if (Object.keys(loggedIn).length !== 0) return <Route exact path='/settings' component={AccountInformation} />;
      return null;
    }
    return null;
  };

  // branch redirects start
  const redirectToUserProfile = (username) => {
    if (typeof window !== 'undefined') {
      window.history.replaceState(null, '', `/${username}`);
      window.location.reload();
    }
  };

  let branchId;
  if (typeof window !== 'undefined') {
    branchId = new URLSearchParams(window.location.search).get('_branch_match_id');
  }
  const userToken = Cookies.get('token');
  const sharedEndedStreamRedirection = (channelId) => {
    const data = new FormData();
    data.append('channel_id', channelId);
    axios.post('/user/get-user-id', data, { headers: { 'device-id': `${deviceId}`, 'x-kumu-auth': `${userToken}` } })
      .then(((response) => {
        if (response.status === 200) {
          if (response.data.code === '200') {
            const { username } = response.data.data;
            redirectToUserProfile(username);
          }
        }
      }))
      .catch((error) => {
        console.error(error);
      });
  };

  const sharedStreamRedirection = (branchData) => {
    let userGuid = '';
    if (branchData.shared_id) {
      userGuid = branchData.shared_id;
    } else if (branchData.userId) {
      userGuid = branchData.userId;
    } else if (branchData.user_id) {
      userGuid = branchData.user_id;
    }
    if (userGuid) {
      const userData = new FormData();
      userData.append('guid', userGuid);
      axios.post('/user/get-user-id', userData, { headers: { 'device-id': `${deviceId}`, 'x-kumu-auth': `${userToken}` } })
        .then(((res) => {
          if (res.status === 200) {
            if (res.data.code === '200') {
              const { username } = res.data.data;
              redirectToUserProfile(username);
            }
          }
        }))
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const sharedProfileRedirection = (branchData) => {
    const username = branchData.userName;
    redirectToUserProfile(username);
  };

  const sharedTimelineFeedRedirection = (branchData) => {
    const feedData = new FormData();
    feedData.append('guid', branchData.user_ID);
    axios.post('/user/get-user-id', feedData, { headers: { 'device-id': `${deviceId}`, 'x-kumu-auth': `${userToken}` } })
      .then(((userRes) => {
        if (userRes.status === 200) {
          if (userRes.data.code === '200') {
            const { username } = userRes.data.data;
            redirectToUserProfile(username);
          }
        }
      }))
      .catch((error) => {
        console.error(error);
      });
  };

  const sharedKlipRedirection = (branchData) => {
    const feedId = branchData.userId;
    const username = branchData.userName;
    if (typeof window !== 'undefined') {
      window.history.replaceState(null, '', `/${username}/klips/${feedId}`);
      window.location.reload();
    }
  };

  const checkBranchData = (branchData) => {
    // share stream
    if (branchData.type === '5' || branchData.type === 5 || ((branchData.type === '2' || branchData.type === 2) && branchData.channel_ID)) {
      sharedStreamRedirection(branchData);
    }
    // share profile
    else if (branchData.kumu_type === 'user_profile') {
      sharedProfileRedirection(branchData);
    }
    // share timeline feed
    else if ((branchData.type === '1' || branchData.type === 1) && branchData.user_ID) {
      sharedTimelineFeedRedirection(branchData);
    }
    // share klips
    else if (branchData.type === '1' || branchData.type === 1 || branchData.type === '4' || branchData.type === 4) {
      sharedKlipRedirection(branchData);
    }
    // other types of share
    else if (branchData.channel_id) {
      sharedEndedStreamRedirection(branchData.channel_id);
    } else if (branchData.userId) {
      sharedEndedStreamRedirection(branchData.userId);
    }
  };

  const manageAppRedirection = () => {
    const branchKey = process.env.REACT_APP_BRANCH_KEY;
    if (branchId) {
      const options = { branch_match_id: branchId };
      // initialize branch using dynamic imports
      import('branch-sdk').then(({ default: branch }) => {
        branch.init(
          branchKey,
          options,
        );

        branch.data((_err, data) => {
          const branchData = data.data_parsed;
          if (branchData) {
            checkBranchData(branchData);
          }
        });
      });
    }
  };

  useEffect(() => {
    manageAppRedirection();
    // add CT user profile
    if (loggedIn) {
      if (typeof window !== 'undefined') {
        import('clevertap-web-sdk').then(({ default: clevertap }) => {
          clevertap.onUserLogin.push({
            Site: {
              Name: loggedIn.nickname,
              Identity: loggedIn.guid,
            },
          });
        });
      }
    }
  });
  // branch redirects end

  return (
    <>
      {onMaintenance === 'true' ? (
        <div id='maintenance-on'>
          <Switch>
            <UserContext.Provider value={providerValue}>
              <Navbar />
              <Switch>
                <MaintenancePage />
              </Switch>
              <div className='footer'>
                <Footer />
              </div>
            </UserContext.Provider>
          </Switch>
        </div>
      ) : (
        <LastLocationProvider>
          <Switch>
            <UserContext.Provider value={providerValue}>
              <StreamSegmentContext.Provider value={providerCoinsContext}>
                <Navbar />
                <Switch>
                  <Route key='referral-leaderboard' exact path='/referral-leaderboard' component={ReferralLeaderboard} />
                  { process.env.REACT_APP_COINS_REFACTOR === 'true'
                    && [
                      <Route key='payment' exact path='/coins/payment' component={Payment} />,
                      <Route key='status' exact path='/coins/payment/status' component={CoinPaymentStatus} />,
                      <Route key='coins' exact path='/coins' component={Coins} />,
                    ]}
                  {
                    (process.env.REACT_APP_WALLET === 'true')
                      && [
                        <Route key='wallet' exact path='/wallet' component={Wallet} />,
                        <Route key='wallet' exact path='/wallet-error-page' component={ErrorWallet} />,
                      ]
                  }
                  <Route exact path='/' component={HomePage} />
                  <Route exact path={['/shops']}>
                    <Redirect to='/' />
                  </Route>
                  <Route exact path={['/live', '/klips']}>
                    <Live />
                  </Route>
                  <Route exact path='/search/index' component={SearchPage} />
                  <Route exact path='/klips/:tag' component={KlipsInner} />
                  <Route path='/:username/klips/:id' component={ProfileKlips} />
                  {settingsRoute()}
                  <Route exact path={['/:username', '/:username/campaign']}>
                    <Profile />
                  </Route>
                </Switch>
                <Footer />
              </StreamSegmentContext.Provider>
            </UserContext.Provider>
          </Switch>
        </LastLocationProvider>
      )}

      { banModalStatus === true ? renderBanOrLockModal() : '' }
      <div className='cookieBanner'>
        <CookieConsent
          location='bottom'
          buttonText='I accept'
          cookieName='popupCookies'
          buttonClasses='cookie-btn-accept'
          expires={30}
        >
          <Typography className='cookie-msg'>COOKIES: By using this website, you automatically accept that we can use cookies.</Typography>
        </CookieConsent>
      </div>
    </>
  );
}

export default App;
